<template>
  <div
    style="width: 500px"
    class="mb-4"
  >
    <v-expansion-panels
      v-model="panel"
      multiple
    >
      <v-expansion-panel
        v-for="(group, groupName) in filterGroups"
        :key="groupName"
      >
        <v-expansion-panel-header>{{ group.label }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col
              v-for="item in group.items"
              :key="item.label"
              cols="12"
              md="6"
            >
              <v-checkbox
                v-model="filters[item.model]"
                :label="item.label"
                @change="onCheckboxChange"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  props: {
    initialFilters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      panel: [0],
      filters: {
        staticIp: false,
        pppoe: false,
        dynamicIp: false,
        hotspot: false,
        activePlan: false,
        expiredPlan: false,
        internetAccess: false,
        noInternetAccess: false,
        debt: false,
        noDebt: false,
        credits: false,
        noInternetPlan: false,
        noPhoneNumber: false,
      },
      filterGroups: {
        serviceStatus: {
          label: 'Service Status',
          items: [
            { model: 'internetAccess', label: 'Internet Access' },
            { model: 'noInternetAccess', label: 'No Internet Access' },
            { model: 'debt', label: 'Debt' },
            { model: 'noDebt', label: 'No Debt' },
            { model: 'credits', label: 'Credits' },
            { model: 'activePlan', label: 'Active Plan' },
            { model: 'expiredPlan', label: 'Expired Plan' },
            { model: 'noInternetPlan', label: 'No Internet Plan' },
            { model: 'exemptedFromDisconnection', label: 'Exempted From Disconnection' },
            { model: 'dormant', label: 'Dormant Account' },
            { model: 'noPhoneNumber', label: 'No Phone Number' },
          ],
        },
        configurationDetails: {
          label: 'Configuration Details',
          items: [
            { model: 'staticIp', label: 'Static IP' },
            { model: 'pppoe', label: 'PPPoE' },
            { model: 'dynamicIp', label: 'Dynamic IP' },
            { model: 'hotspot', label: 'Hotspot' },
          ],
        },
      },
      filterLabel: 'all customers',
    }
  },
  mounted() {
    if (this.initialFilters) {
      this.filters = this.initialFilters
    }
    this.onCheckboxChange()
  },
  methods: {
    onCheckboxChange() {
      this.generateFilterLabel()
      this.$emit('status-change', {
        filters: this.filters,
        label: this.filterLabel,
      })
    },
    generateFilterLabel() {
      const filterLabels = this.generateLabelsFromFilters()
      this.filterLabel = filterLabels.join(', ').replace(/, ([^,]*)$/, ' and $1') || 'all customers'
    },
    generateLabelsFromFilters() {
      return Object.entries(this.filters).reduce((acc, [key, value]) => {
        if (value) {
          const filterGroupEntry = this.findFilterGroupEntry(key)
          if (filterGroupEntry) acc.push(filterGroupEntry.label)
        }

        return acc
      }, [])
    },
    findFilterGroupEntry(filterModel) {
      // Convert filterGroups object into an array of [groupName, group] pairs
      const entries = Object.entries(this.filterGroups)

      // Use array methods to find the first matching entry
      for (let i = 0; i < entries.length; i++) {
        const [, group] = entries[i] // Destructure to get the group object
        const entry = group.items.find(item => item.model === filterModel)
        if (entry) {
          return entry
        }
      }

      // Return null if no matching entry is found
      return null
    },

  },
}
</script>
